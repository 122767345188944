.carousel-caption p, .carousel-caption h3 {
    color: black;
}

div.carousel-caption {
    position: relative;
    left: 0;
}

.carousel-item img {
    max-width: 1400px;
    margin: auto;
    padding-bottom: 20px;
}

h4.text-center {
    margin-bottom: 20px;
}

nav.navbar-dark {
    margin-bottom: 20px;
}